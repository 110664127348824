import gql from 'graphql-tag';

export const API_CHARGERS_SUBSCRIPTION = gql`
	subscription ApiChargersSubscription($facility_id: bigint) {
		api_charger(where: { facility_id: { _eq: $facility_id } }) {
			is_connected
			status
		}
	}
`;

export const API_CHARGER_SUBSCRIPTION = gql`
	subscription ApiChargerSubscription($id: bigint!) {
		api_charger_by_pk(id: $id) {
			is_connected
			status
		}
	}
`;

export const API_CHARGER_AND_PORT = gql`
	subscription ApiChargerAndPortSubscription($chargerId: bigint!, $portId: bigint!) {
		api_charger_by_pk(id: $chargerId) {
			is_connected
			status
			api_chargerports(where: { id: { _eq: $portId } }) {
				port_status
			}
		}
	}
`;

export const API_CHARGER_AND_PORT_BY_FACILITY = gql`
	subscription ApiChargerAndPortByFacilitySubscription($id: bigint!) {
		api_charger(where: { facility_id: { _eq: $id } }) {
			id
			is_connected
			status
			api_chargerports {
				id
				port_status
			}
		}
	}
`;

export const API_SESSION_SUBSCRIPTION = gql`
	subscription ApiSessionSubscription($sessionid: String!) {
		api_chargersession(where: { session_id: { _eq: $sessionid } }) {
			total_amount_spent
			units_consumed
			user_charge_request_input
			status
			start_at
			stop_at
			session_end_reason
			is_penalty_calculating
			penalty_price
			penalty_fee
			penalty_paid
			overtime_second
			battery_stop_at
			port_type
			invoice_url
			final_kwh_balance
			initial_kwh_balance
			initial_wallet_balance
			final_wallet_balance
			is_kwh
			power_import
			voltage
			current
			coupon_discount
			rating
			rating_denied
			feedback
		}
	}
`;

//home screen #get all running charging session, started by user
export const API_RUNNING_SESSION_SUBSCRIPTION = gql`
	subscription ApiRunningSessionSubscription($token: String!) {
		view_facility_revenue(
			where: {
				_and: {
					_or: [
						{ status: { _in: ["Charging"] } }
						{ is_penalty_calculating: { _eq: true } }
						{ _and: { penalty_fee: { _gt: 0 } }, penalty_paid: { _eq: false } }
					]
				}
				api_user: { token: { _eq: $token } }
			}
		) {
			battery_stop_at
			charger_name
			connector_id
			facility_address
			facility_name
			port_type
			power_type
			session_id
			start_at
			status
			total_amount_spent
			units_consumed
			user_charge_request_input
			battery_start_at
			user_charge_request_on
			final_wallet_balance
			per_unit
			calculate_on_unit
			is_penalty_calculating
			penalty_fee
			penalty_paid
			user_id
			device_id
		}
	}
`;

export const API_CURRENCY_SUBSCRIPTION = gql`
	subscription ApiCurrencySubscription($token: String!) {
		api_user(where: { token: { _eq: $token } }) {
			api_country {
				currency
			}
		}
	}
`;

export const API_CURRENCY_QUERY = gql`
	query ApiCurrencySubscription($token: String!) {
		api_user(where: { token: { _eq: $token } }) {
			api_country {
				currency
			}
		}
	}
`;

